<template>
  <div class="pay">
    <BaseHeaderBar
      title="Kode pembayaran"
      :showCustomerService="false"
      backBtnType="2"
      :showBackButton="true"
      color="#FFF"
      bgColor="#FB4F5D"
    />
    <div class="content">
      <div class="pay-amount">
        <p class="label">Jumlah pembayaran</p>
        <p class="amount">
          Rp{{ formatNumberWithCommas(payInfo.padryAmount) }}
        </p>
      </div>
      <div class="pay-info-list">
        <div>
          <p class="label">Nama Bank</p>
          <p class="value">{{ payInfo.padryChannel }}</p>
        </div>
        <div>
          <p class="label">Metode pembayaran</p>
          <p class="value">{{ payInfo.padryWay }}</p>
        </div>
        <div>
          <p class="label">Masa berlaku</p>
          <p class="value">{{ payInfo.lodrseTime }}</p>
        </div>
        <div
          v-clipboard:copy="payInfo.padrymentCode"
          v-clipboard:success="onCopySuccess"
          v-clipboard:error="onCopyError"
        >
          <p class="label">
            Kode pembayaran<img
              class="copy-btn"
              src="@/assets/img/copy-btn.jpg"
            />
          </p>
          <p class="value">{{ payInfo.padrymentCode }}</p>
        </div>
      </div>
    </div>
    <van-tabs
      class="extra-info"
      v-model="active"
      animated
      swipeable
      :ellipsis="false"
      color="#FB4F5D"
      title-active-color="#FA4F5D"
      title-inactive-color="#333"
      background="#F4F5F6"
    >
      <van-tab title="Hal yang perlu diperhatikan">
        <div class="tab-content">
          <p v-for="(item, index) in payInfo.prdrecautions.step" :key="index">
            {{ item }}
          </p>
        </div>
      </van-tab>
      <van-tab title="Cara Pembayaran">
        <div class="tab-content">
          <p
            v-for="(item, index) in payInfo.redrpaymentProcess[0].step"
            :key="index"
          >
            {{ item }}
          </p>
        </div>
      </van-tab>
    </van-tabs>
    <button type="button" class="paid-btn" @click="onPaid">
      Saya sudah melakukan pembayaran
    </button>
    <van-dialog
      v-model="visible"
      :showConfirmButton="false"
      close-on-click-overlay
    >
      <img
        class="repayment-success-icon"
        src="@/assets/img/repayment-success-icon.png"
      />
      <p class="tip">Pembayaran berhasil！</p>
      <button
        v-if="payInfo.padryCodeid"
        type="button"
        class="ok-btn"
        @click="onConfirm"
      >
        ok
      </button>
    </van-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { Tab, Tabs, Dialog, Toast } from 'vant'
import dialogVisible from '@/mixins/dialogVisible'
import request from '@/utils/request'
import webviewBack from '@/mixins/webviewBack'
import VueClipboard from 'vue-clipboard2'

Vue.use(Tab)
Vue.use(Tabs)
Vue.use(VueClipboard)

export default {
  name: 'Pay',
  mixins: [dialogVisible, webviewBack],
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  data () {
    return {
      payInfo: {
        prdrecautions: {},
        redrpaymentProcess: [{}]
      },
      active: 0
    }
  },
  created () {
    this.getPayCode()
  },
  methods: {
    getPayCode () {
      const { repayType, amount, orderId, channelId, payChannel, name, payWay, payWayName } = this.$route.query
      const postData = {
        ordrderID: orderId,
        padryAmount: amount,
        padryType: repayType,
        padrymentChannel: channelId,
        padryChannel: payChannel,
        padryChannelName: name,
        padryWay: payWay,
        padryWayName: payWayName
      }
      request.post('crdreatePaymentCode', postData)
        .then(res => {
          this.payInfo = res
        })
    },
    onCopySuccess () {
      Toast('Berhasil disalin')
    },
    onCopyError () {
      Toast('Penyalinan gagal')
    },
    onPaid () {
      const { orderId } = this.$route.query
      const postData = {
        ordrderID: orderId,
        padryCodeid: this.payInfo.padryCodeid
      }
      request.post('redrpaymentStatus', postData)
        .then(res => {
          if (res.padryStatus === 2) {
            this.visible = true
          } else {
            Toast(res.padryMsg)
          }
        })
    },
    onConfirm () {
      this.onCloseDialog()
      this.$router.push('/home')
    }
  }
}
</script>

<style scoped lang="less">
.pay {
  min-height: 100vh;
  overflow: hidden;
  background-color: @theme-color;
}

.content {
  width: 654px;
  box-shadow: 0 30px 40px 0 rgba(183, 183, 183, 0.1),
    0 -20px 30px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px 4px 0 0;
  margin: 41px auto 0;
  background-color: #fff;
}

.pay-amount {
  text-align: center;

  .label {
    padding-top: 26px;
    padding-bottom: 14px;
    font-size: 30px;
    color: #fb4f5d;
  }

  .amount {
    padding-top: 16px;
    padding-bottom: 45px;
    border-top: 2px solid #e6e6e7;
    font-size: 56px;
    font-weight: 600;
    color: #ef3d53;
  }
}

.pay-info-list {
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    line-height: 90px;
    padding-left: 32px;
    padding-right: 32px;
    border-top: 2px solid #e6e6e7;

    .label {
      display: flex;
      align-items: center;
      font-size: 24px;
      color: #a4a7b5;
    }

    .copy-btn {
      width: 30px;
      height: 30px;
      margin-left: 20px;
    }

    .value {
      font-size: 26px;
      color: #666a7b;
    }
  }
}

.extra-info {
  width: 654px;
  border-radius: 1px;
  margin: 0 auto;
  background-color: @body-bg-color;

  /deep/ .van-tab__text {
    font-size: 26px;
  }

  /deep/ .van-tabs__nav--card {
    margin: 0;
    border: none;
  }
}

.tab-content {
  height: 415px;
  padding: 39px 42px 0;
  margin-bottom: 53px;
  overflow-y: scroll;

  p {
    line-height: 37px;
    font-size: 26px;
    color: #666;

    &:nth-child(n + 2) {
      margin-top: 40px;
    }
  }
}

.paid-btn {
  width: 654px;
  height: 112px;
  line-height: 112px;
  border-radius: 4px;
  border: 4px solid #fff;
  margin: 30px auto 50px;
  font-size: 30px;
  color: #fff;
  text-align: center;
  background: none;
}

.repayment-success-icon {
  width: 238px;
  height: 199px;
  margin: 24px auto 0;
}

.tip {
  line-height: 42px;
  margin-top: 40px;
  font-size: 30px;
  color: @theme-color;
  text-align: center;
}

.ok-btn {
  width: 629px;
  height: 88px;
  line-height: 88px;
  border-radius: 8px;
  border: 4px solid #fff;
  margin: 60px auto 30px;
  font-size: 30px;
  color: #f9f9f9;
  text-align: center;
  background-color: @theme-color;
}
</style>
